import(/* webpackMode: "eager", webpackExports: ["TokenRefreshProvider"] */ "/home/runner/work/in2event-front-end/in2event-front-end/apps/in2event-launchpad/src/context/token-refresh-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/in2event-front-end/in2event-front-end/apps/in2event-launchpad/src/lib/feature-flags/context.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/in2event-front-end/in2event-front-end/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin_muq646v64cwhmqbwopniw46jjm/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/in2event-front-end/in2event-front-end/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin_muq646v64cwhmqbwopniw46jjm/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/in2event-front-end/in2event-front-end/apps/in2event-launchpad/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/home/runner/work/in2event-front-end/in2event-front-end/node_modules/.pnpm/nuqs@2.0.0_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin-m_vir3rjvffsaozbxecc52lkzwrm/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleProvider"] */ "/home/runner/work/in2event-front-end/in2event-front-end/packages/i18n/src/locale-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/in2event-front-end/in2event-front-end/packages/in2event-components/src/components/index.ts");
