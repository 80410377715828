'use client';

import { type ReactNode, createContext, useContext } from 'react';

import type { FeatureFlagKey, FeatureFlags } from './types';

const FeatureFlagsContext = createContext<FeatureFlags>({} as FeatureFlags);

interface FeatureFlagsProviderProps {
    children: ReactNode;
    flags: FeatureFlags;
}

export const FeatureFlagsProvider = ({ children, flags }: FeatureFlagsProviderProps) => {
    return <FeatureFlagsContext.Provider value={flags}>{children}</FeatureFlagsContext.Provider>;
};

export function useFeatureFlags() {
    const context = useContext(FeatureFlagsContext);
    return context;
}

export function useFeatureFlag(flag: FeatureFlagKey) {
    const flags = useFeatureFlags();
    return flags[flag] ?? false;
}
